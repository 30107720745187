import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from '../src/components/screens/Home'
import Nav from './components/screens/Nav';
import Alert from './components/screens/Alert';
import Alert1 from './components/screens/Alert1';
import Welcome from './components/screens/Redirect';
import Protected from './components/Protected';
import Redirect from './components/screens/Redirect';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  return (
    <>
      <Nav />
      <Routes>
        {/* CONCATENATE("http://localhost:/",application_id,"/",apprI,"/",aplcntName,"/",aprvdAmount,"/",apprIEmail) */}
        <Route exact path="/home/:application_Id/:appr_tag/:applicantName/:approvedAmount/:apprEmail" element={<Redirect/>} />
        <Route exact path="/home/:application_Id/:appr_tag/:applicantName/:approvedAmount/:apprEmail/:userEmail/:isEmail" element={<Redirect/>} />
        <Route exact path="/arka/:value" element={<Protected Component={Home} />} />
        <Route path="/arka/alert" element={<Alert />}/>
        <Route path="/wlcome" element={<Welcome />}/>
        <Route path='/arka/alert1' element={<Alert1/>}/>
        {/* </Suspense> */}
      </Routes>
    </>);
}

export default App;
