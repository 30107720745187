import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookies';
import './Home.css'
import { Numberformat } from "./Numberformate";
import Loader from "./Loader";
import { AES } from "crypto-js";
import { decode,  } from "base-64";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Card, Container } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


function Home() {
    const navigate = useNavigate();
    const token = '18afd312-00cf-3d71-bde3-b3761e8df440';
    let pass = '7SP&n5B9Cu6A5M8z';

    // let { application_Id, appr_tag, applicantName, approvedAmount, apprEmail } = useParams();
    let { value } = useParams();
    let c = decode(value);
    let data = JSON.parse(c);
    // let decrapt = AES.decrypt(encrpt, pass);
    // let d1 = decrapt.toString(CryptoJS.enc.Utf8);
    // let data = JSON.parse(d1);

    const [refreshing, setRefreshing] = useState(0);
    const [comment, setComment] = useState('');
    const [application_id, setApplication_id] = useState(data.application_Id);
    const [approver_email, setApprover_emal] = useState(data.apprEmail);
    const [approval_amount, setApproval_amount] = useState(data.approvedAmount);
    const [applicant_name, setApplication_name] = useState(data.applicantName);
    const [approver_name, setApprover_name] = useState("xyz");
    const [approver_comment, setApprover_comment] = useState(comment);
    const [loan_status, setLoan_status] = useState('')
    const [showAlert, setShowAlert] = useState(false);
    const [approver_tag, setApprover_tag] = useState(data.appr_tag);
    const [user_Email,setUserEmail] = useState(data.userEmail ? data.userEmail : 'NO');
    const [is_Email,setIsEmail] = useState(data.isEmail ? data.isEmail : 'NO');
    const payload = { application_id, applicant_name, approval_amount, approver_tag, approver_comment, approver_email, loan_status,user_Email, is_Email, created_at: '' };
    let str = JSON.stringify(payload);


    // DB2022062823037

    const updateCommentValue = (e) => {
        const v = e.target.value;
        setApprover_comment(v);
    }


    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));


    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );



    useEffect(() => {
        if (loan_status === "approved") {
            setLoan_status("approved")
            insertLoanDetail()

        }
        else if (loan_status === "rejected") {
            setLoan_status("rejected")
            insertLoanDetail()

        }
    }, [loan_status])



    useEffect(() => {
        setTimeout(() => {
            setRefreshing(0);
            setShowAlert(false);
        }, 1500);
    }, [showAlert])


    const insertLoanDetail = () => {
        let encrpt = AES.encrypt(str, pass).toString();
        let payload1 = { value: encrpt };

        setRefreshing(1);
        // https://approval.arkafincap.com/users/insert-loan-detail
        const config = { headers: { authorization: `Bearer ${'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0aW9uIiwidXNlclR5cGUiOiJ3aG9sZXNhbGUiLCJpYXQiOjE2NzU2NjYyMzR9.GrADzNxuhqCypJdM1xw7Db2k4AhBZ_PB_zDCmeAozkw'}` } }; 
        axios.post(`https://approval.arkafincap.com/users/insert-loan-detail`, payload1,config)   // http://10.2.109.204:8080/status
            .then(response => {
                if (response.status === 200) {
                    setRefreshing(1);
                    goToNextPage();
                }
                else {
                    // alert("something went wrong in insertlocalresponse!")
                    setShowAlert(true);
                }
            })
            .catch(error => {
                // this.setState({ errorMessage: error.message });
                setRefreshing(1)
                setShowAlert(true);
                // alert("something went wrong in insertlocalresponse!")
                //  console.error( error);
            });
    }

    const goToNextPage = () => {
        Cookies.setItem("token", "APPROVED")
        navigate('/arka/alert');
    }   



    return (
        <div className="containers-fluid mt-1" style={{ backgroundColor: 'white' }}>
            {
                showAlert ? <div class="alert alert-danger" role="alert">
                    Something went wrong !
                </div> : null
            }

            <Container maxWidth="sm">
                <Card elevation={3} sx={{ marginTop: 5, border: 0.2, borderColor: 'gray', borderRadius: 3 }}>
                    <CardContent>
                        <Typography sx={{ mb: 5, backgroundColor: '#06444a', padding: 1, color: 'orange', borderRadius: 2 }} variant="h6" component="div">
                            Details for approval
                        </Typography>
                        <Box sx={{ flexGrow: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} >
                                    <TextField
                                        fullWidth
                                        value={data.application_Id}
                                        id="outlined-read-only-input"
                                        label="Application ID"
                                        defaultValue="Hello World"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={data.applicantName}
                                        id="outlined-read-only-input"
                                        label="Applicant Name"
                                        defaultValue="Hello World"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={data.apprEmail}
                                        id="outlined-read-only-input"
                                        label="Email"
                                        defaultValue="Hello World"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={Numberformat(data.approvedAmount)}
                                        id="outlined-read-only-input"
                                        label="Approval Amount"
                                        defaultValue="Hello World"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        value={approver_comment} onChange={(text) => updateCommentValue(text)}
                                        id="outlined-read-only-input"
                                        label="Comment"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                    <CardActions fullWidth sx={{ display: 'flex', pb: 3, flexDirection: 'row', justifyContent: 'space-around' }} >
                        <Button sx={{ backgroundColor: '#06444a' }} onClick={() => setLoan_status("approved")} variant="contained">
                            <Typography style={{ textTransform: 'none', color: 'orange' }}>Approve</Typography>

                        </Button>
                        <Button sx={{ backgroundColor: '#06444a' }} onClick={() => setLoan_status("rejected")} variant="contained">
                            <Typography style={{ textTransform: 'none', color: 'orange' }}>Reject</Typography>

                        </Button>
                    </CardActions>
                </Card>
            </Container>
            {refreshing ? <Loader /> : null}

        </div>

    );
}

export default Home;
