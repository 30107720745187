import {  Navigate, useParams } from 'react-router-dom';
import { decode, encode } from "base-64";


// encodeURIComponent(crypto.AES.encrypt(term.toString(), config.CRYPTO_PASSPHRASE_RES).toString());

function Redirect() {
    // let pass = '7SP&n5B9Cu6A5M8z';
    let { application_Id, appr_tag, applicantName, approvedAmount, apprEmail,userEmail, isEmail } = useParams();
    const payload = {application_Id:application_Id, appr_tag:appr_tag, applicantName:applicantName, approvedAmount:approvedAmount, apprEmail:apprEmail, userEmail:userEmail, isEmail:isEmail}
    let str = JSON.stringify(payload);
    let a = str.toString();
    let b = encode(a);
    let c = decode(b);
    let d = JSON.parse(c);


    // let encrpt = AES.encrypt(str, pass).toString();

    return (
        <>
            <div>
               <Navigate replace to={'/arka/'+b}/>
            </div>
        </>
    );
}

export default Redirect;
