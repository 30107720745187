import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);


function Alert() {
    return (
        <>
        <div className="align-middle" style={{marginTop:30,alignSelf:'center'}}>
        <Box fullHeighst fullWidth sx={{alignItems:'center',justifyContent:'center'}} >
            <Box sx={{ flexGrow: 1 }}>
                <Grid container style={{alignItems:'center',justifyContent:'center'}} spacing={2}>
                    <Grid item xs={12} md={6} >
                        <Card sx={{ backgroundColor:'#06444a' }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>

                                </Typography>
                                <Typography sx={{color:'orange'}} variant="h5" component="div">
                                    Thank You !
                                </Typography>

                                <Typography sx={{color:'orange'}}  variant="body1">
                                    Your response has been Submitted
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid></Box>
                </Box>
                </div>
        </>
    );
}

export default Alert;
